import{
  BrowserRouter,
  Routes,
  Route
} from  "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Prd from "./components/Prd";
import AddPrd from "./components/AddPrd";
import EditPrd from "./components/EditPrd";
import ColorSetting from "./components/ColorSetting.js";
import EditColorSetting from "./components/EditColorSetting";
import SpaceSetting from "./components/SpaceSetting.js";
import EditSpaceSetting from "./components/EditSpaceSetting";
import ComponentSetting from "./components/ComponentSetting.js";
import AddComponentSetting from "./components/AddComponentSetting.js";
import EditComponentSetting from "./components/EditComponentSetting.js";
import MetaTag from "./components/MetaTag.js";
import AddMetaTag from "./components/AddMetaTag";
import EditMetaTag from "./components/EditMetaTag";
import Dpwd from "./components/Dpwd";
import AddDpwd from "./components/AddDpwd";
import ChangeLogo from "./components/ChangeLogo";
import ButtonGradientSetting from "./components/ButtonGradientSetting";
import EditButtonGradientSetting from "./components/EditButtonGradientSetting";
import Script from "./components/Script";
import AddScript from "./components/AddScript";
import EditScript from "./components/EditScript";


/*<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous"/>*/

 
function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route exact path="/script" element={<><Navbar/><Script/></>}></Route>
        <Route exact path="/editscript/:id" element={<><Navbar/><EditScript/></>}></Route>
        <Route exact path="/addscript" element={<><Navbar/><AddScript/></>}></Route>

        <Route exact path="/editbuttongradientsetting/:id" element={<><Navbar/><EditButtonGradientSetting/></>}></Route>
        <Route exact path="/buttongradient" element={<><Navbar/><ButtonGradientSetting/></>}></Route>
        <Route exact path="/changelogo" element={<><Navbar/><ChangeLogo/></>}></Route>
        <Route exact path="/adddpwd" element={<><Navbar/><AddDpwd/></>}></Route>
        <Route exact path="/dpwd" element={<><Navbar/><Dpwd/></>}></Route>
        <Route exact path="/componentsetting" element={<><Navbar/><ComponentSetting/></>}></Route>
        <Route exact path="/editcomponent/:id" element={<><Navbar/><EditComponentSetting/></>}></Route>
        <Route exact path="/addcomponentsetting/:position_after" element={<><Navbar/><link rel="stylesheet" href="custom.css"/><AddComponentSetting/></>}></Route>
        <Route exact path="/editmetatag/:id" element={<><Navbar/><EditMetaTag/></>}></Route>
        <Route exact path="/addmetatag" element={<><Navbar/><AddMetaTag/></>}></Route>
        <Route exact path="/metatag" element={<><Navbar/><MetaTag/></>}></Route>
        <Route exact path="/spacesetting" element={<><Navbar/><SpaceSetting/></>}></Route>
        <Route exact path="/editspacesetting/:id" element={<><Navbar/><EditSpaceSetting/></>}></Route>
        <Route exact path="/colorsetting" element={<><Navbar/><ColorSetting/></>}></Route>
        <Route exact path="/editcolorsetting/:id" element={<><Navbar/><EditColorSetting/></>}></Route>
        <Route exact path="/addprd" element={<><Navbar/><AddPrd/></>}></Route>
        <Route exact path="/editprd/:id" element={<><Navbar/><EditPrd/></>}></Route>
        <Route exact path="/prd" element={<><Navbar/><Prd/></>}></Route>
        <Route exact path="/login" element={<Login/>}></Route>
        <Route path="/" element={<><Navbar/><Dashboard/></>}></Route>
        <Route path="/dashboard" element={<><Navbar/><Dashboard/></>}></Route>
        <Route exact path="/register" element={<Register/>}></Route>

      </Routes>
    </BrowserRouter>
    </>
  );
}
 
export default App;