import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const Prd = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [prds, setPrds] = useState([]);
    const navigate = useNavigate();
 
    const [currentPage, setCurrentPage] = useState(1); // State for the current page
    const [totalPages, setTotalPages] = useState(0); // State for total pages
    const itemsPerPage = 10; // Number of items per page

    // useEffect(() => {
    //     document.title = appName;
    //     refreshToken();
    //     getPrds();        
    // }, [currentPage]);

useEffect(() => {
  document.title = appName;

  const fetchData = async () => {
    await refreshToken(); // Ensure token is refreshed first
    getPrds();            // Then fetch the paginated data
  };

  fetchData();
}, [currentPage]); // Dependency array includes currentPage to fetch new data when the page changes

 
    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            console.log("refreshToken:"+response.data.accessToken);
            // getPrds();
        } catch (error) {
            console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    // axiosJWT.interceptors.request.use(async (config) => {
    //     const currentDate = new Date();
    //     // console.log("expire*1000 = "+expire*1000);
    //     // console.log("currentDate.getTime() = "+currentDate.getTime());
    //     if (expire * 1000 < currentDate.getTime()) {
    //         console.log("do refreshToken");
    //         const response = await axios.get(apiUrl+'/token');
    //         config.headers.Authorization = `Bearer ${response.data.accessToken}`;
    //         setToken(response.data.accessToken);
    //         const decoded = jwtDecode(response.data.accessToken);
    //         setName(decoded.name);
    //         setExpire(decoded.exp);
    //         // console.log("expire:"+expire);
    //     }
    //     return config;
    // }, (error) => {
    //     return Promise.reject(error);
    // });

axiosJWT.interceptors.request.use(
  async (config) => {
    console.log('Interceptor triggered');
    const currentDate = new Date();
    if (expire * 1000 < currentDate.getTime()) {
      console.log('Token expired, refreshing...');
      const response = await axios.get(apiUrl + '/token');
      config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      setToken(response.data.accessToken);
      const decoded = jwtDecode(response.data.accessToken);
      setName(decoded.name);
      setExpire(decoded.exp);
    } else {
      console.log('Token is still valid');
    }
    return config;
  },
  (error) => {
    console.error('Error in interceptor:', error);
    return Promise.reject(error);
  }
);
 

    // const getPrds = async () => {
    //     const response = await axiosJWT.get(
    //     apiUrl+'/prds',{
    //         params:{
    //             page:currentPage,
    //             limit:itemsPerPage,
    //         }
    //     }
    //     );
    //     console.log("getPrds response : "+response.data);
    //     setPrds(response.data.data);
    //     setTotalPages(response.data.totalPages);
    // }

const getPrds = async () => {
  try {
    const response = await axiosJWT.get(apiUrl + '/prds', {
      params: {
        page: currentPage,
        limit: itemsPerPage,
      },
      headers: {
        Authorization: `Bearer ${token}`, // Explicitly set the token in case interceptor has issues
      },
    });
    setPrds(response.data.data);
    setTotalPages(response.data.totalPages);
  } catch (error) {
    console.error('Error fetching products:', error);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., token might be invalid or expired)
      navigate('/login');
    }
  }
};

    // Function to handle page change
      const handlePageChange = (page) => {
        // Ensure the page number is within valid range
        if (page > 0 && page <= totalPages) {
          setCurrentPage(page);
        }
      };

    const handleDelete = async(id)=>{
        const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer '+token,
        },
        };           
        try{
        await axiosJWT.delete(
                apiUrl+"/prd/delete/"+id,
                config
                )
            window.location.reload()
        }catch(err){
            console.log(err)
        }
    }

    const renderHtml = ( text ) => {
        // console.log("html value : "+text);
        return <>{text && <span dangerouslySetInnerHTML={{ __html: text }} />}</>;
    };

 
    return (
        <>
        <div className="container py-5 px-3">
            <p className="title is-3">Prd</p>
            <div className="has-text-right">
                <a href="/addprd" className="button is-light is-small">Add new data</a>
            </div>
            <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th width="10%">No</th>
                        <th width="20%">Title</th>
                        <th width="20%">Image</th>
                        <th width="25%">Date</th>
                        <th width="25%">Text</th>
                        <th width="25%"></th>
                    </tr>
                </thead>
                <tbody>
                    {prds.map((prd, index) => (
                        <tr key={prd.id}>
                            <td>{index + 1}</td>
                            <td>{prd.title}</td>
                            <td><img src={`${apiUrl}/public/img/prd/${prd.image}`} className="is-4by3" /></td>
                            <td>{prd.date}</td>
                            <td>{renderHtml(prd.text)}</td>
                            <td><button className="button is-light is-small"><Link to={`/editprd/${prd.id}`}>Update</Link></button>&nbsp;<button className="button is-light is-small" onClick={()=>handleDelete(prd.id)}>Delete</button></td>
                        </tr>
                    ))} 
                </tbody>
            </table>     

      <div>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        <span>
          &nbsp;Page {currentPage} of {totalPages}&nbsp;
        </span>

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>


        </div>
        </>
    )

}
 
export default Prd