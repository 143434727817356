import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {Link} from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const AddDpwd = () => {
    const navigate = useNavigate();

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [msg, setMsg] = useState('');


    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [name, setName] = useState('');
    const [type,setType] = useState('dp');
    const [count, setCount] = useState('20');

    useEffect(() => {
      document.title = appName;
        refreshToken();        
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("refreshToken:"+response.data.accessToken);
            // getPrd(id);
        } catch (error) {
            // console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const savePrd = async (e) => {
        e.preventDefault();


        // // Step 1: Define an array of 10 names
        // const namesArray = [
        //   'Aldi Prabowo',
        //   'Bintang Putra',
        //   'Cinta Dewi',
        //   'Dimas Nugroho',
        //   'Eka Sari',
        //   'Faris Ramadhan',
        //   'Gina Wijaya',
        //   'Hani Lestari',
        //   'Iwan Setiawan',
        //   'Jasmine Sari',
        //   'Kiki Wulandari',
        //   'Luca Santoso',
        //   'Maya Pratama',
        //   'Nico Saputra',
        //   'Oka Hartono',
        //   'Putra Aditya',
        //   'Rina Sari',
        //   'Satria Widodo',
        //   'Tariq Ali',
        //   'Uli Kusuma',
        //   'Vina Kusumawardhani',
        //   'Wawan Supriyadi',
        //   'Xena Lestari',
        //   'Yudi Rahman',
        //   'Zara Putri',
        //   'Ariani Kusuma',
        //   'Bima Surya',
        //   'Candra Indah',
        //   'Dewi Amalia',
        //   'Elang Arifin',
        //   'Fika Rahma',
        //   'Guntur Prabowo',
        //   'Hilda Mariana',
        //   'Indra Wijaya',
        //   'Johan Tanjung',
        //   'Kirana Putri',
        //   'Lila Farhana',
        //   'Milan Hadi',
        //   'Nia Arista',
        //   'Omar Setiawan',
        //   'Putu Sari',
        //   'Rama Yudha',
        //   'Siska Oktavia',
        //   'Teguh Santoso',
        //   'Ujang Hermawan',
        //   'Vera Andini',
        //   'Wira Cahyadi',
        //   'Xena Wulandari',
        //   'Yani Hartati',
        //   'Zulfiqar Hadi'
        // ];
        // // Step 2: Function to randomly select 5 unique names
        // const getRandomNames = (arr, count) => {
        //   const shuffled = arr.sort(() => 0.5 - Math.random()); // Shuffle the array
        //   return shuffled.slice(0, count); // Return the first 'count' elements
        // };

        // // Step 3: Function to generate a random value between the specified range
        // const getRandomValue = (min, max, step) => {
        //   const range = Math.floor((max - min) / step) + 1; // Calculate the number of possible steps
        //   return Math.floor(Math.random() * range) * step + min; // Generate a random value within the range
        // };

        // // Step 4: Put the selected names into a JSON object with random values
        // const selectedNames = getRandomNames(namesArray, count);
        // const namesJson = selectedNames.reduce((obj, name) => {
        //   if(type=="dp"){
        //   obj[name] = getRandomValue(10000, 200000, 1000); // Assign a random value to each name
        //   }
        //   if(type=="wd"){
        //   obj[name] = getRandomValue(1000000, 20000000, 500000); // Assign a random value to each name
        //   }
        //   return obj;
        // }, {});

        // console.log(namesJson);

        const config = {
        headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer '+token,
        },
        };
        try {
            await axiosJWT.post(
                apiUrl+'/dpwd/add',
                {
                    type: type,
                    date_from: dateFrom,
                    date_to: dateTo,
                    count:count,
                    // value:namesJson
                },
                config
            );
            // navigate.push("/");
            navigate("/dpwd");
        } catch (error) {
            console.log("savePrd error : "+error);
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
     const handleSelectChange = (event) => {
        setCount(event.target.value);
    };

    return (
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <p className="title is-3">Add</p>
                            <div className="has-text-right my-2">
                            <a href="/prd" className="button is-light is-small">Back</a>
                            </div>
                            <form onSubmit={savePrd} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="radios mt-2">
                                  <label className="radio">
                                    <input type="radio" name="type" onChange={(e)=>setType('dp')} checked={type === "dp"}/>
                                    DP
                                  </label>
                                  <label className="radio">
                                    <input type="radio" name="type" onChange={(e)=>setType('wd')} checked={type === "wd"}/>
                                    WD
                                  </label>
                                </div>

                               <div className="field mt-5">
                                    <label className="label">Date From</label>
                                    <div className="controls">
                                        <input type="date" className="input" placeholder="Date"
                                            value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                                    </div>
                                </div>
                               <div className="field mt-5">
                                    <label className="label">Date To</label>
                                    <div className="controls">
                                        <input type="date" className="input" placeholder="Date"
                                            value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
                                    </div>
                                </div>

                                <div className="field mt-2">
                                    <label className="label">Count</label>
                                    <div className="controls">
                                        <select name="component" className="input" value={count} onChange={handleSelectChange}>
                                        <option value="20">20</option>
                                        </select>
                                    </div>
                                </div>



                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
 
export default AddDpwd