import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const EditSpaceSetting = () => {

    const [settingComponent, setSettingComponent] = useState('');
    const [settingMt, setSettingMt] = useState('');
    const [settingMr, setSettingMr] = useState('');
    const [settingMb, setSettingMb] = useState('');
    const [settingMl, setSettingMl] = useState('');
    const [settingPt, setSettingPt] = useState('');
    const [settingPr, setSettingPr] = useState('');
    const [settingPb, setSettingPb] = useState('');
    const [settingPl, setSettingPl] = useState('');

    const [msg, setMsg] = useState('');
    const [color, setColor] = useState('');

    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [name, setName] = useState('');

    const [prd, setPrd] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    
    // console.log("id:"+id);

    useEffect(() => {
      document.title = appName;
      refreshToken();   
    }, []);


    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("refreshToken:"+response.data.accessToken);
            getSpaceSetting(id);
        } catch (error) {
            console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const getSpaceSetting = async (id) => {
        const response = await axiosJWT.get(
            apiUrl+'/spacesetting/'+id
        );
        console.log("getSpaceSetting response:"+response.data);

        setSettingComponent(response.data.component_name);

        setSettingMt(response.data.mt);
        setSettingMr(response.data.mr);
        setSettingMb(response.data.mb);
        setSettingMl(response.data.ml);
        setSettingPt(response.data.pt);
        setSettingPr(response.data.pr);
        setSettingPb(response.data.pb);
        setSettingPl(response.data.pl);
    }

    const savePrd = async (e) => {
        e.preventDefault();
        const config = {
        headers: {
            'Authorization': 'Bearer '+token,
        },
        };

        try {
            await axiosJWT.put(
                apiUrl+'/updatespacesetting/'+id,
                {
                    component_name: settingComponent,
                    mt: settingMt,
                    mr: settingMr,
                    mb: settingMb,
                    ml: settingMl,
                    pt: settingPt,
                    pr: settingPr,
                    pb: settingPb,
                    pl: settingPl,

                },
                config
            );
            // navigate.push("/");
            navigate("/spacesetting");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
     
      // const changeColor = (val) => {
      //   setColor(val);
      //   console.log("color chosen : " + color);
      // };


    return (
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column">
                            <p className="title is-3">Edit Spacing</p>
                            <div className="has-text-right my-2">
                            <a href="/spacesetting" className="button is-light is-small">Back</a>
                            </div>
                            <div className="columns">
                                <div className="column is-8">
                                    <form onSubmit={savePrd} className="box">
                                        <p className="has-text-centered">{msg}</p>
                                        <div className="field mt-5">
                                            <label className="label">Component</label>
                                            <div className="controls">
                                                <input type="text" className="input" placeholder="Name"
                                                    value={settingComponent} onChange={(e) => setSettingComponent(e.target.value)} />
                                            </div>
                                        </div>


                                        <div className="field mt-5">
                                            <label className="label">Margin Top</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="mt" checked={settingMt === 0} onChange={(e) => setSettingMt(0)} />
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mt" checked={settingMt === 1} onChange={(e) => setSettingMt(1)} />
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mt" checked={settingMt === 2} onChange={(e) => setSettingMt(2)} />
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mt" checked={settingMt === 3} onChange={(e) => setSettingMt(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mt" checked={settingMt === 4} onChange={(e) => setSettingMt(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mt" checked={settingMt === 5} onChange={(e) => setSettingMt(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>


                                        <div className="field mt-5">
                                            <label className="label">Margin Right</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="mr" checked={settingMr === 0} onChange={(e) => setSettingMr(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mr" checked={settingMr === 1} onChange={(e) => setSettingMr(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mr" checked={settingMr === 2} onChange={(e) => setSettingMr(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mr" checked={settingMr === 3} onChange={(e) => setSettingMr(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mr" checked={settingMr === 4} onChange={(e) => setSettingMr(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mr" checked={settingMr === 5} onChange={(e) => setSettingMr(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>


                                        <div className="field mt-5">
                                            <label className="label">Margin Bottom</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="mb" checked={settingMb === 0} onChange={(e) => setSettingMb(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mb" checked={settingMb === 1} onChange={(e) => setSettingMb(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mb" checked={settingMb === 2} onChange={(e) => setSettingMb(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mb" checked={settingMb === 3} onChange={(e) => setSettingMb(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mb" checked={settingMb === 4} onChange={(e) => setSettingMb(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="mb" checked={settingMb === 5} onChange={(e) => setSettingMb(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>

                                        <div className="field mt-5">
                                            <label className="label">Margin Left</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="ml" checked={settingMl === 0} onChange={(e) => setSettingMl(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="ml" checked={settingMl === 1} onChange={(e) => setSettingMl(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="ml" checked={settingMl === 2} onChange={(e) => setSettingMl(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="ml" checked={settingMl === 3} onChange={(e) => setSettingMl(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="ml" checked={settingMl === 4} onChange={(e) => setSettingMl(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="ml" checked={settingMl === 5} onChange={(e) => setSettingMl(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>

                                        <div className="field mt-5">
                                            <label className="label">Padding Top</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="pt" checked={settingPt === 0} onChange={(e) => setSettingPt(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pt" checked={settingPt === 1} onChange={(e) => setSettingPt(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pt" checked={settingPt === 2} onChange={(e) => setSettingPt(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pt" checked={settingPt === 3} onChange={(e) => setSettingPt(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pt" checked={settingPt === 4} onChange={(e) => setSettingPt(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pt" checked={settingPt === 5} onChange={(e) => setSettingPt(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>


                                        <div className="field mt-5">
                                            <label className="label">Padding Right</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="pr" checked={settingPr === 0} onChange={(e) => setSettingPr(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pr" checked={settingPr === 1} onChange={(e) => setSettingPr(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pr" checked={settingPr === 2} onChange={(e) => setSettingPr(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pr" checked={settingPr === 3} onChange={(e) => setSettingPr(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pr" checked={settingPr === 4} onChange={(e) => setSettingPr(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pr" checked={settingPr === 5} onChange={(e) => setSettingPr(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>

                                        <div className="field mt-5">
                                            <label className="label">Padding Bottom</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="pb" checked={settingPb === 0} onChange={(e) => setSettingPb(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pb" checked={settingPb === 1} onChange={(e) => setSettingPb(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pb" checked={settingPb === 2} onChange={(e) => setSettingPb(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pb" checked={settingPb === 3} onChange={(e) => setSettingPb(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pb" checked={settingPb === 4} onChange={(e) => setSettingPb(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pb" checked={settingPb === 5} onChange={(e) => setSettingPb(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>

                                        <div className="field mt-5">
                                            <label className="label">Padding Left</label>
                                            <div className="control">
                                              <label className="radio">
                                                <input type="radio" name="pl" checked={settingPl === 0} onChange={(e) => setSettingPl(0)}/>
                                                no space
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pl" checked={settingPl === 1} onChange={(e) => setSettingPl(1)}/>
                                                0.25rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pl" checked={settingPl === 2} onChange={(e) => setSettingPl(2)}/>
                                                0.5rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pl" checked={settingPl === 3} onChange={(e) => setSettingPl(3)}/>
                                                0.75rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pl" checked={settingPl === 4} onChange={(e) => setSettingPl(4)}/>
                                                1rem
                                              </label>
                                              <label className="radio">
                                                <input type="radio" name="pl" checked={settingPl === 5} onChange={(e) => setSettingPl(5)}/>
                                                1.5rem
                                              </label>
                                            </div>
                                        </div>

                                        <div className="field mt-5">
                                            <button className="button is-success is-fullwidth">Save</button>
                                        </div>
                                    </form>

                                </div>
                                <div className="column is-4"><img src="/img/margin-padding-exaplanation.jpg"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )      
    


}
 
export default EditSpaceSetting