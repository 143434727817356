// src/Navbar.js
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

function Navbar() {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);

    // Function to toggle the navbar menu
    const toggleNavbar = () => {
    setIsActive(!isActive);
    };

    const Logout = async () => {
    try {
    await axios.delete(apiUrl+'/logout');
    // history.push("/");
    navigate("/login");
    } catch (error) {
    console.log(error);
    }
    }
    useEffect(() => {
        document.title = appName;        
    }, []);

  return (
        <nav className="navbar is-black" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                <p className="title is-3">PRD</p>
                </a>

                <button className={`navbar-burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" onClick={toggleNavbar}>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                </button>
            </div>

            <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                <div className="navbar-start">
                    <a className="navbar-item" href="/dashboard">Home</a>
                    <a className="navbar-item" href="/prd">PRD</a>
                    <a className="navbar-item" href="/colorsetting">Color Setting</a>
                    <a className="navbar-item" href="/spacesetting">Space Setting</a>
                    <a className="navbar-item" href="/componentsetting">Component Setting</a>
                    <a className="navbar-item" href="/metatag">Meta Tag</a>
                    <a className="navbar-item" href="/dpwd">DPWD</a>
                    <a className="navbar-item" href="/buttongradient">Button Gradient</a>
                    <a className="navbar-item" href="/script">Script</a>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button onClick={Logout} className="button is-light">Log Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
  );
}

export default Navbar;