import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const ButtonGradientSetting = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [settings, setSettings] = useState([]);
    const navigate = useNavigate();
 
    useEffect(() => {
        document.title = appName;
        refreshToken();        
    }, []);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("refreshToken:"+response.data.accessToken);
            getSettings();
        } catch (error) {
            console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

 

    const getSettings = async () => {
        const response = await axiosJWT.get(
        apiUrl+'/buttongradientsettings'
        );
        console.log("getSettings response : "+response.data);
        setSettings(response.data);
    }
 

    const handleDelete = async(id)=>{
        const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer '+token,
        },
        };           
        try{
        await axiosJWT.delete(
                apiUrl+"/deletecolorsetting/"+id,
                config
                )
            window.location.reload()
        }catch(err){
            console.log(err)
        }
    }
      // Generate the linear-gradient style based on colorData
      const gradientStyle = {
        background: `linear-gradient(135deg, ${
          settings.map(data => `rgba(${data.value_r}, ${data.value_g}, ${data.value_b}, ${data.value_a * 100}%)`)
            .join(', ')
        })`
      };



 
    return (
        <>

        <div className="container py-5 px-3">

            <p className="title is-3">Button Gradient Settings</p>

            <div class="columns">
                <div className="column is-half"><button className="button is-fullwidth" style={{ ...gradientStyle, color: '#fff', padding:'10px 20px', animation:'gradient 5s ease infinite'}}>BUTTON PREVIEW</button></div>
                <div className="column is-half"><button className="button is-fullwidth" style={{ ...gradientStyle, color: '#fff', padding:'10px 20px', animation:'gradient 5s ease infinite'}}>BUTTON PREVIEW</button></div>                     
            </div>

            
            <table className="table is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th width="2%">No</th>
                        <th width="15%">R</th>
                        <th width="15%">G</th>
                        <th width="15%">B</th>
                        <th width="15%">A</th>
                        <th width="25%"></th>
                        <th width="25%"></th>
                    </tr>
                </thead>
                <tbody>
                    {settings.map((setting, index) => (
                        
                        <tr key={setting.id}>
                            <td>{index + 1}</td>
                            <td>{setting.value_r}</td>
                            <td>{setting.value_g}</td>
                            <td>{setting.value_b}</td>
                            <td>{setting.value_a}</td>
                            <td><span className="tag tagColor" style={{ backgroundColor: `rgba(${setting.value_r},${setting.value_g},${setting.value_b},${setting.value_a})`}}> &nbsp; </span>&nbsp;rgba({setting.value_r},{setting.value_g},{setting.value_b},{setting.value_a})</td>
                            <td><button className="button is-light is-small"><Link to={`/editbuttongradientsetting/${setting.id}`}>Update</Link></button></td>
                        </tr>
                        )
                    )} 
                </tbody>
            </table>     

            <div class="columns">
                <div className="column is-half"><button className="button is-fullwidth" style={{ ...gradientStyle, color: '#fff', padding:'10px 20px', animation:'gradient 5s ease infinite'}}>BUTTON PREVIEW</button></div>
                <div className="column is-half"><button className="button is-fullwidth" style={{ ...gradientStyle, color: '#fff', padding:'10px 20px', animation:'gradient 5s ease infinite'}}>BUTTON PREVIEW</button></div>                     
            </div>
            


        </div>
        </>
    )



}
 
export default ButtonGradientSetting