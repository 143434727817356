import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const ComponentSetting = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [settings, setSettings] = useState([]);
    const navigate = useNavigate();
 
    const [mainComponents, setMainComponents] = useState([]);
    const [customComponents, setCustomComponents] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // useEffect(() => {
    //     refreshToken();
        
    // }, []);



      useEffect(() => {
        document.title = appName;
        refreshToken();
      }, []);

 
    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            console.log("refreshToken:"+response.data.accessToken);
            getMainComponents();
            


        } catch (error) {
            console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


  useEffect(() => {
    // Fetch categories from the server with Authorization header using axios

  }, []);
 

    const getMainComponents = async () => {
        // const response = await axiosJWT.get(
        // 'http://localhost:5000/componentsettings/main'
        // );
        // console.log("getSettings response : "+response.data);
        // setSettings(response.data);


    axiosJWT.get(apiUrl+'/componentsettings/main')
      .then((response) => {
        const data = response.data;
        if (!Array.isArray(data) || data.length === 0) {
          throw new Error('No categories available');
        }
        setMainComponents(data);

        // Fetch items for each category with Authorization header using axios
        data.forEach((maincomponent) => {
          axiosJWT.get(apiUrl+`/customcomponents/${maincomponent.id}`)
            .then((response) => {
              const itemsData = response.data;
              setCustomComponents((prevItems) => ({
                ...prevItems,
                [maincomponent.id]: itemsData,
              }));
            })
            .catch((error) =>
              console.error('Error fetching items:', error.message)
            );
        });
      })
      .catch((error) => {
        setError(error.message);
        console.error('Error fetching categories:', error.message);
      })
      .finally(() => setLoading(false));

    }
 

    const handleDelete = async(id)=>{
        const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer '+token,
        },
        };           
        try{
        await axiosJWT.delete(
                apiUrl+"/deletecolorsetting/"+id,
                config
                )
            window.location.reload()
        }catch(err){
            console.log(err)
        }
    }
    function removeComponent(id){
        // alert(id);
        if(window.confirm("Are you sure?")){
            // alert(id);

            const config = {
            headers: {
            'Authorization': 'Bearer '+token,
            },
            };           
            try{
             axiosJWT.delete(
                    apiUrl+"/deletecomponent/"+id,
                    config
                    )
                window.location.reload()
            }catch(err){
                console.log(err)
            }
        }
    }

const renderHtml = ( text ) => {
//console.log("html value : "+text);
return <>{text && <span style={{ fontSize: 'small' }} dangerouslySetInnerHTML={{ __html: text }} />}</>;
};

    // return (
    // <div className="container py-5 px-3">
    //         <p className="title is-3">Component Settings</p>
    //         <p className="subtitle is-5">Subtitle 5</p>
      
    //     {settings.map((setting, index) => {
    //       // Check if this is not the last item
    //       const isNotLast = index !== settings.length - 1;
    //       const prevId = setting.id;
    //       if (isNotLast) {
    //             return (
    //               <React.Fragment key={setting.id}>
    //                 <section className="hero is-small is-primary mb-5 has-text-centered">
    //                   <div className="hero-body">
    //                     <p className="title">{setting.name}</p>
    //                   </div>
    //                 </section>
    //                 <div className="hero is-small is-primary mb-5 has-text-centered">
    //                   <button className="button is-light">
    //                     <Link to={`/addcomponentsetting/${setting.id}`}>
    //                       insert component here
    //                     </Link>
    //                   </button>
    //                 </div>
    //               </React.Fragment>
    //             );
    //       }
    //       else{
    //         // console.log(index);
    //         return (<section key={setting.id} className="hero is-small is-primary mb-5 has-text-centered">
    //                <div className="hero-body">
    //                             <p className="title">{setting.name}</p>
    //                           </div>
    //                         </section>
    //                         );
    //       }
    //     })}  
    // </div>
    // )

  return (
   <>
        <div className="container py-5 px-3">
            <p className="title is-3">Component Settings</p>
            <div className="columns">
                <div className="column is-10-desktop">
                {mainComponents.map((maincomponent,index) => {
                const isNotLast = index !== mainComponents.length - 1;
                const prevId = maincomponent.id;
                let changeLogoLink = "";
                if(maincomponent.name=="NAVBAR"){
                    changeLogoLink = '<a href="/changelogo" className="button is-link"><button>change image</button></a>';
                    //dangerouslySetInnerHTML={{ __html: text }}
                }
                if (isNotLast) {
                    return (
                    <React.Fragment key={maincomponent.id}>
                    <section className="hero is-small is-primary mb-5 has-text-centered">
                    <div className="hero-body">
                    <p className="title">{maincomponent.name} {renderHtml(changeLogoLink)}</p>
                    </div>
                    </section>
                    {customComponents[maincomponent.id] ? (
                    customComponents[maincomponent.id].map((custom, index) => (
                        <div className="notification is-light has-text-centered" key={custom.id}>

                          <button className="delete" onClick={() => removeComponent(custom.id)}></button>
                          <p className="title">{custom.name} <Link style={{fontSize:'small'}} to={`/editcomponent/${custom.id}`}>Update</Link></p>
                        </div>
                    ))
                    ) : (
                    <li>Loading...</li>
                    )}                    
                    <div className="hero is-small is-primary mb-5 has-text-centered">
                    <a href={`/addcomponentsetting/${maincomponent.id}`} className="button is-link">INSERT COMPONENT  HERE</a>
                    </div>
                    </React.Fragment>
                    );
                }
                else{
                    return (<section key={maincomponent.id} className="hero is-small is-primary mb-5 has-text-centered">
                    <div className="hero-body">
                    <p className="title">{maincomponent.name}</p>
                    </div>
                    </section>
                    );
                }
                }
                )}
                </div>
                <div className="column is-2-desktop">
                    <section className="hero is-small is-primary mb-5 has-text-centered">
                    <div className="hero-body">
                    <p>Main Component</p>
                    </div>
                    </section>
                    <section className="hero is-small is-light mb-5 has-text-centered">
                    <div className="hero-body">
                    <p>Custom Component</p>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </>
  );

}
 
export default ComponentSetting