import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;
 
const EditMetaTag = () => {

    const [metaName, setMetaName] = useState('');
    const [metaValue, setMetaValue] = useState('');
    const [msg, setMsg] = useState('');

    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [name, setName] = useState('');

    const [prd, setPrd] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    
    // console.log("id:"+id);

    useEffect(() => {
        document.title = appName;
        refreshToken();   
    }, []);


    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("refreshToken:"+response.data.accessToken);
            getMetaTag(id);
        } catch (error) {
            console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const getMetaTag = async (id) => {
        const response = await axiosJWT.get(
            apiUrl+'/metatag/'+id
        );
        console.log("getMetaTag response:"+response.data);
        // setPrd(response.data);
        setMetaName(response.data.name);
        setMetaValue(response.data.value);
    }

    const savePrd = async (e) => {
        e.preventDefault();
        const config = {
        headers: {
        // 'content-type': 'multipart/form-data',
        'Authorization': 'Bearer '+token,
        },
        };

        try {
            console.log("meta name :"+metaName);
            console.log("meta value :"+metaValue);
            await axiosJWT.put(
                apiUrl+'/metatag/update/'+id,
                {
                    name: metaName,
                    value: metaValue,
                },
                config
            );
            // navigate.push("/");
            navigate("/metatag");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
 
    return (
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <p className="title is-3">Edit Meta Tag</p>
                            <div className="has-text-right my-2">
                            <a href="/metatag" className="button is-light is-small">Back</a>
                            </div>


                            <form onSubmit={savePrd} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Name</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Name"
                                            value={metaName} onChange={(e) => setMetaName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Value</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Value"
                                            value={metaValue} onChange={(e) => setMetaValue(e.target.value)} />
                                    </div>
                                </div>

                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
 
export default EditMetaTag