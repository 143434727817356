import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {Link} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { useLocation } from 'react-router-dom';
import '../carousel.css'; // Custom CSS for additional styling

// import 'bootstrap/dist/css/bootstrap.min.css';
// import ExampleCarouselImage from 'react-bootstrap/components/ExampleCarouselImage';
const MAX_COUNT = 5;
const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const AddComponentSetting = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const positionAfter = location.pathname.split("/")[2];
    // console.log(id);
    
    const [msg, setMsg] = useState('');
    const [preview,setPreview] = useState('');


    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [name, setName] = useState('');


    const [valueText1, setValueText1] = useState('');
    const [valueLink1, setValueLink1] = useState('');
    const [valueText2, setValueText2] = useState('');
    const [valueLink2, setValueLink2] = useState('');
    const [valueText3, setValueText3] = useState('');
    const [valueLink3, setValueLink3] = useState('');
    const [bannerImage,setBannerImage] = useState('');
    const [carouselFiles, setCarouselFiles] = useState([]);
    const [selectedOption, setSelectedOption] = useState('carousel');
    const [componentName,setComponentName] = useState('');
    const [componentId, setComponentId] = useState('');
    const [componentStyle, setComponentStyle] = useState('');
    const [errors, setErrors] = useState([]);

    const [buttonStyle,setButtonStyle] = useState('default');

    useEffect(() => {
        document.title = appName;
        refreshToken();        
    }, []);


    const handleFileChange = (event) => {
        setCarouselFiles(event.target.files);
        console.log("files:"+carouselFiles);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            console.log("refreshToken:"+response.data.accessToken);
            // getPrd(id);
        } catch (error) {
            // console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const savePrd = async (e) => {
        e.preventDefault();
        console.log('savecomponent');
        const component = e.target.component.value;
        console.log('savecomponent '+component);
        console.log("apiUrl"+apiUrl);

        const formData = new FormData();
        if(component=="carousel"){
            if(componentName==""){
                setMsg("Component name is required");
                return;
            }
            if(componentId==""){
                setMsg("Component ID is required");
                return;
            }            
            if (carouselFiles=="" || typeof carouselFiles === 'undefined'){
                setMsg("Carousel Images is required");
                return;
            }     

            const config = {
                headers: {
                // 'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+token,
                },
            };
            for (let i = 0; i < carouselFiles.length; i++) {
                formData.append('images', carouselFiles[i]);
            }
            formData.append('name', componentName);
            formData.append('component_id', componentId);
            formData.append('component_style', componentStyle);
            formData.append('position_after',positionAfter);
            console.log(formData);
            try {
                await axiosJWT.post(
                apiUrl+'/carousel/add',
                formData,
                config
                );                
                // navigate.push("/");
                navigate("/componentsetting");
            } catch (error) {
                console.log("savePrd error : "+error);
                if (error.response) {
                setMsg(error.response.data.msg);
                }
            }
        }
        if(component=="banner_image"){
            console.log(bannerImage);
            if(componentName==""){
                setMsg("Component name is required");
                return;
            }
            if(componentId==""){
                setMsg("Component ID is required");
                return;
            }            
            if (bannerImage=="" || typeof bannerImage === 'undefined'){
                setMsg("Image is required");
                return;
            }            

            const config = {
                timeout: 60000,
                headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+token,
                },
            };
            try {
                await axiosJWT.post(
                
                apiUrl+'/banner/add',
                {
                    name:componentName,
                    image: bannerImage,
                    component_id:componentId,
                    component_style:componentStyle,
                    position_after:positionAfter,
                },            
                config

                );                
                // navigate.push("/");
                navigate("/componentsetting");
            } catch (error) {
                console.log("savePrd error : "+error);
                if (error.response) {
                setMsg(error.response.data.msg);
                }
            }
        }
        if(component=="button3"){
            if(componentName==""){
                setMsg("Component name is required");
                return;
            }
            if(componentId==""){
                setMsg("Component ID is required");
                return;
            }
            if(valueText1=="" || valueText2=="" || valueText3==""){
                setMsg("Please fill all the button text");
                return;
            }
            if(valueLink1=="" || valueLink2=="" || valueLink3==""){
                setMsg("Please fill all the button link");
                return;
            }
            const config = {
                headers: {
                // 'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+token,
                },
            };
            try {
                await axiosJWT.post(
                apiUrl+'/button3/add',
                {
                    name:componentName,
                    value_text: valueText1+"|"+valueText2+"|"+valueText3,
                    value_link: valueLink1+"|"+valueLink2+"|"+valueLink3,
                    component_id:componentId,
                    component_style:componentStyle,
                    position_after:positionAfter,
                    button_style:buttonStyle,
                },            
                config
                );                
                // navigate.push("/");
                navigate("/componentsetting");
            } catch (error) {
                console.log("savePrd error : "+error);
                if (error.response) {
                setMsg(error.response.data.msg);
                }
            }
        }

        if(component=="button2"){
            if(componentName==""){
                setMsg("Component name is required");
                return;
            }
            if(componentId==""){
                setMsg("Component ID is required");
                return;
            }
            if(valueText1=="" || valueText2==""){
                setMsg("Please fill all the button text");
                return;
            }
            if(valueLink1=="" || valueLink2==""){
                setMsg("Please fill all the button link");
                return;
            }
            const config = {
                headers: {
                // 'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+token,
                },
            };
            try {
                await axiosJWT.post(
                apiUrl+'/button2/add',
                {
                    name:componentName,
                    value_text: valueText1+"|"+valueText2,
                    value_link: valueLink1+"|"+valueLink2,
                    component_id:componentId,
                    component_style:componentStyle,
                    position_after:positionAfter,
                    button_style:buttonStyle,
                },            
                config
                );                
                // navigate.push("/");
                navigate("/componentsetting");
            } catch (error) {
                console.log("savePrd error : "+error);
                if (error.response) {
                setMsg(error.response.data.msg);
                }
            }

        }
    }
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    };

    const items = [
        { src: '/img/carousel/1.jpg', alt: 'Slide 1' },
        { src: '/img/carousel/2.jpg', alt: 'Slide 2' },
        { src: '/img/carousel/3.jpg', alt: 'Slide 3' },
    ];
    return (
        <>
        <link rel="stylesheet" href="bootstrap/dist/css/bootstrap.min.css" />
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-6-desktop">
                            <p className="title is-3">Add Component</p>
                            <div className="has-text-right my-2">
                            <a href="/componentsetting" className="button is-light is-small">Back</a>
                            </div>
                            <form onSubmit={savePrd} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-2">
                                    <label className="label">Component</label>
                                    <div className="controls">
                                        <select name="component" className="input" value={selectedOption} onChange={handleSelectChange}>
                                        <option value="carousel">Carousel</option>
                                        <option value="banner_image">Banner Image</option>
                                        <option value="button3">3 Button (responsive)</option>
                                        <option value="button2">2 Button (responsive)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="field mt-2">
                                    <label className="label">Component Name</label>
                                    <div className="controls">
                                        <input type="text" name="name" className="input" placeholder="Component Name"
                                            value={componentName} onChange={(e) => setComponentName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-2">
                                    <label className="label">Component ID</label>
                                    <div className="controls">
                                        <input type="text" name="component_id" className="input" placeholder="Component ID"
                                            value={componentId} onChange={(e) => setComponentId(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-2">
                                    <label className="label">Component Style</label>
                                    <div className="controls">
                                        <input type="text" name="component_style" className="input" placeholder="border:1px solid #000000;color:#ffffff;padding:1em;"
                                            value={componentStyle} onChange={(e) => setComponentStyle(e.target.value)} />
                                    </div>
                                </div>



                            {selectedOption === 'button2' && (
                            <>
                                <div className="field mt-2">
                                    <label className="label">Button 1</label>
                                    <div className="controls">
                                        <input type="text" className="input mb-2" placeholder="Text"
                                            value={valueText1} onChange={(e) => setValueText1(e.target.value)} />
                                        <input type="text" className="input mb-2" placeholder="Link"
                                            value={valueLink1} onChange={(e) => setValueLink1(e.target.value)} />
                                   </div>
                                </div>
                                <div className="field mt-2">
                                    <label className="label">Button 2</label>
                                    <div className="controls">
                                        <input type="text" className="input mb-2" placeholder="Text"
                                            value={valueText2} onChange={(e) => setValueText2(e.target.value)} />
                                        <input type="text" className="input mb-2" placeholder="Link"
                                            value={valueLink2} onChange={(e) => setValueLink2(e.target.value)} />
                                   </div>
                                </div>

                                <div className="radios mt-2">
                                  <label className="radio">
                                    <input type="radio" name="style" checked onChange={(e)=>setButtonStyle('default')} />
                                    Defaut Style
                                  </label>
                                  <label className="radio">
                                    <input type="radio" name="style" onChange={(e)=>setButtonStyle('rainbow')} />
                                    Gradient
                                  </label>
                                </div>
                            </>
                            )} 


                            {selectedOption === 'button3' && (
                            <>
                                <div className="field mt-2">
                                    <label className="label">Button 1</label>
                                    <div className="controls">
                                        <input type="text" className="input mb-2" placeholder="Text"
                                            value={valueText1} onChange={(e) => setValueText1(e.target.value)} />
                                        <input type="text" className="input mb-2" placeholder="Link"
                                            value={valueLink1} onChange={(e) => setValueLink1(e.target.value)} />
                                   </div>
                                </div>
                                <div className="field mt-2">
                                    <label className="label">Button 2</label>
                                    <div className="controls">
                                        <input type="text" className="input mb-2" placeholder="Text"
                                            value={valueText2} onChange={(e) => setValueText2(e.target.value)} />
                                        <input type="text" className="input mb-2" placeholder="Link"
                                            value={valueLink2} onChange={(e) => setValueLink2(e.target.value)} />
                                   </div>
                                </div>
                                <div className="field mt-2">
                                    <label className="label">Button 3</label>
                                    <div className="controls">
                                        <input type="text" className="input mb-2" placeholder="Text"
                                            value={valueText3} onChange={(e) => setValueText3(e.target.value)} />
                                        <input type="text" className="input mb-2" placeholder="Link"
                                            value={valueLink3} onChange={(e) => setValueLink3(e.target.value)} />
                                   </div>
                                </div>

                                <div className="radios mt-2">
                                  <label className="radio">
                                    <input type="radio" name="style" checked onChange={(e)=>setButtonStyle('default')} />
                                    Defaut Style
                                  </label>
                                  <label className="radio">
                                    <input type="radio" name="style" onChange={(e)=>setButtonStyle('rainbow')} />
                                    Gradient
                                  </label>
                                </div>
                            </>
                            )} 



                            {selectedOption === 'banner_image' && (
                            <>
                                <div className="field mt-2">
                                    <label className="label">Banner Image</label>
                                    <div className="controls">
                                        <input type="file" className="input" placeholder="Image" accept="image/*" onChange={(e) => setBannerImage(e.target.files[0])} />
                                        
                                   </div>
                                </div>
                            </>
                            )} 


                            {selectedOption === 'carousel' && (
                            <>
                                <div className="field mt-2">
                                    <label className="label">Carousel Image</label>
                                    <div className="controls">
                                        <input type="file" multiple className="input" onChange={handleFileChange} accept="image/*" />
                                        <span className="is-size-7 is-italic">*You can choose multiple images.</span>

                                   </div>
                                </div>
                            </>
                            )} 


                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Save</button>
                                </div>
                            </form>
                        </div>
                        <div className="column is-6-desktop">
                            <p className="title is-3">Preview</p>

                            {selectedOption === 'button2' && (
                            <>
                            <div className="columns">
                              <div className="column"><button className={"button is-fullwidth " + (buttonStyle=="rainbow" ? 'btn-rainbow' : '')}>Button Text 1</button></div>
                              <div className="column"><button className={"button is-fullwidth " + (buttonStyle=="rainbow" ? 'btn-rainbow' : '')}>Button Text 2</button></div>
                            </div>
                            </>
                            )} 


                            {selectedOption === 'button3' && (
                            <>
                            <div className="columns">
                              <div className="column"><button className={"button is-fullwidth " + (buttonStyle=="rainbow" ? 'btn-rainbow' : '')}>Button Text 1</button></div>
                              <div className="column"><button className={"button is-fullwidth " + (buttonStyle=="rainbow" ? 'btn-rainbow' : '')}>Button Text 2</button></div>
                              <div className="column"><button className={"button is-fullwidth " + (buttonStyle=="rainbow" ? 'btn-rainbow' : '')}>Button Text 3</button></div>
                            </div>
                            </>
                            )} 

                            {selectedOption === 'banner_image' && (
                            <>
                            <img src="/img/banner_image_example.jpg" />
                            <span className="is-size-7 is-italic">*Banner image size recommendation : 1000px x 300px.</span>
                            </>
                            )} 

                            {selectedOption === 'carousel' && (
                            <>
                                <div className="carousel">
                                    <button className="carousel-control prev" onClick={prevSlide}>‹</button>
                                    <div className="carousel-items" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                                        {items.map((item, index) => (
                                            <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                                                <img src={item.src} alt={item.alt} />
                                            </div>
                                        ))}
                                    </div>
                                    <button className="carousel-control next" onClick={nextSlide}>›</button>
                                </div>
                                <span className="is-size-7 is-italic">*Carousel image size recommendation : 1000px x 300px.</span>
                            </>
                            )} 
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
 
export default AddComponentSetting