import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { RgbaColorPicker  } from "react-colorful";

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const EditButtonGradientSetting = () => {
    const [valueRed, setValueRed] = useState('');
    const [valueGreen, setValueGreen] = useState('');
    const [valueBlue, setValueBlue] = useState('');
    const [valueAlpha, setValueAlpha] = useState('');

    const [settingName, setSettingName] = useState('');
    const [settingValue, setSettingValue] = useState('');
    const [msg, setMsg] = useState('');
    const [color, setColor] = useState({ r: 200, g: 150, b: 35, a: 0.5 });


    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [name, setName] = useState('');

    const [prd, setPrd] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const id = location.pathname.split("/")[2];
    
    // console.log("id:"+id);

    useEffect(() => {
        document.title = appName;
        refreshToken();   
    }, []);


    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("refreshToken:"+response.data.accessToken);
            getColorSetting(id);
        } catch (error) {
            console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const getColorSetting = async (id) => {
        const response = await axiosJWT.get(
            apiUrl+'/buttongradientsetting/'+id
        );
        console.log("getButtonGradientSetting response:"+response.data.value_r);
        console.log("getButtonGradientSetting response:"+response.data.value_g);
        console.log("getButtonGradientSetting response:"+response.data.value_b);
        console.log("getButtonGradientSetting response:"+response.data.value_a);

        setValueRed(response.data.value_r);
        setValueGreen(response.data.value_g);
        setValueBlue(response.data.value_b);
        setValueAlpha(response.data.value_a);
        const _color = {
            r : Number(response.data.value_r),
            g : Number(response.data.value_g),
            b : Number(response.data.value_b),
            a : Number(response.data.value_a),

        }
        console.log(_color);
        setColor(_color);
    }

    const savePrd = async (e) => {
        e.preventDefault();
        const config = {
        headers: {
            'Authorization': 'Bearer '+token,
        },
        };

        try {
            await axiosJWT.put(
                apiUrl+'/updatebuttongradientsetting/'+id,
                {
                    value_r: color.r,
                    value_g: color.g,
                    value_b: color.b,
                    value_a: color.a,
                },
                config
            );
            // navigate.push("/");
            navigate("/buttongradient");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
     

    return (
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <p className="subtitle is-5">Subtitle 5</p>
                            <div className="has-text-right my-2">
                            <a href="/buttongradient" className="button is-light is-small">Back</a>
                            </div>
                            <form onSubmit={savePrd} className="box">
                                <p className="has-text-centered">{msg}</p>

                                <div className="field mt-5">
                                    <label className="label">Value</label>
                                    <div className="controls">

                                    <div className="has-text-centered is-fullwidth">

<>
  <div className="field-body mb-2">
    <div className="field is-expanded">
      <div className="field has-addons">
        <p className="control is-expanded">
        {JSON.stringify(color)}
        </p>
        
      </div>
    </div>
  </div>
</>


                                    <RgbaColorPicker color={color} onChange={setColor}  />
                                    {
                                    /*<div className="value" style={{ borderLeftColor: color }}>
                                    Current color is {color}
                                    </div>*/
                                    }
                                    </div>
                                        
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
 
export default EditButtonGradientSetting