import React, { useState,useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {Link} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { useLocation } from 'react-router-dom';
import '../carousel.css'; // Custom CSS for additional styling

// import 'bootstrap/dist/css/bootstrap.min.css';
// import ExampleCarouselImage from 'react-bootstrap/components/ExampleCarouselImage';
const MAX_COUNT = 5;
const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const AddComponentSetting = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const positionAfter = location.pathname.split("/")[2];
    // console.log(id);
    
    const [msg, setMsg] = useState('');
    const [preview,setPreview] = useState('');


    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [name, setName] = useState('');


    const [logoImage,setLogoImage] = useState('');
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        document.title = appName;
        refreshToken();        
    }, []);






    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            console.log("refreshToken:"+response.data.accessToken);
            // getPrd(id);
        } catch (error) {
            // console.log("refreshToken error : "+error);
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        // console.log("expire*1000 = "+expire*1000);
        // console.log("currentDate.getTime() = "+currentDate.getTime());
        if (expire * 1000 < currentDate.getTime()) {
            console.log("do refreshToken");
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // console.log("expire:"+expire);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const savePrd = async (e) => {
        e.preventDefault();

        // const formData = new FormData();

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer '+token,
                },
            };
            try {
                await axiosJWT.put(
                apiUrl+'/logo/update',
                {
                    image: logoImage,
                },            
                config
                );                
                // navigate.push("/");
                navigate("/componentsetting");
            } catch (error) {
                console.log("savePrd error : "+error);
                if (error.response) {
                setMsg(error.response.data.msg);
                }
            }


    }


    return (
        <>
        <link rel="stylesheet" href="bootstrap/dist/css/bootstrap.min.css" />
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-6-desktop">
                            <p className="title is-3">Change Logo</p>
                            <div className="has-text-right my-2">
                            <a href="/componentsetting" className="button is-light is-small">Back</a>
                            </div>
                            <form onSubmit={savePrd} className="box">
                                <p className="has-text-centered">{msg}</p>

                                <div className="field mt-2">
                                    <label className="label">Logo Image</label>
                                    <div className="controls">
                                        <input type="file" className="input" placeholder="Image" accept="image/*" onChange={(e) => setLogoImage(e.target.files[0])} />
                                        
                                   </div>
                                </div>










                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Save</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
 
export default AddComponentSetting